<template>
  <ion-footer>
    <ion-toolbar class="mx-0">
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col>
            <ion-row>
              <ion-text>{{ label }}</ion-text>
            </ion-row>
            <ion-row>
              <ion-label class="f-price">{{ priceFormatter(currencySymbol, totalAmount) }}</ion-label>
            </ion-row>
          </ion-col>
          <ion-col size="auto">
            <ion-button
              color="primary"
              fill="solid"
              @click="$emit('handlePayInvoices')"
              :disabled="disabledPay"
            >
              <ion-icon color="light" class="f-icon" :icon="shieldCheckmarkOutline"></ion-icon>
              <ion-label>{{ payInvoices }}</ion-label>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-footer>
</template>
<script>
import { priceFormatter } from '@/utils/';
import { shieldCheckmarkOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('sale/payment');

export default defineComponent({
  name: 'bottom-content',
  emits: ['handlePayInvoices'],
  props: ['disabledPay'],
  setup() {
    const { t } = useI18n();
    const payInvoices = t('pay');
    const label = t('total_invoice_amount');
    return {
      payInvoices,
      label,
      shieldCheckmarkOutline,
      priceFormatter
    };
  },
  computed: {
    ...mapGetters(['selectedPaymentInvoices']),
    totalAmount() {
      return this.selectedPaymentInvoices?.totalPaidAmount;
    },
    currencySymbol() {
      return this.selectedPaymentInvoices?.currencySymbol ?? 'S$';
    }
  }
});
</script>
<style lang="scss" scoped>
ion-button {
  width: fit-content;
  --border-radius: 10px;
  height: 44px;
  ion-label {
    padding: 2rem 1rem 2rem 0;
  }
  ion-icon {
    padding-left: 1rem;
    padding-right: 6px;
  }
}
ion-label {
  text-transform: capitalize;
}
.f-price {
  font-weight: bold;
  font-size: 22px;
  margin-top: 0.7rem;
}
ion-card {
  margin-bottom: 1px;
  border: none;
}
</style>
