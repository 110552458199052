<template>
  <div>
    <div class="box mx-3 mb-2" @click="openPicker(0)" v-if="isBankTransfer">
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col size="auto">
            <ion-icon class="f-icon" :icon="calendarOutline"></ion-icon>
          </ion-col>
          <ion-col>
            <ion-row>
              <ion-label class="fs-2 pb-2 text-gray-600"> {{ labelTransaction }}</ion-label>
            </ion-row>
            <ion-row>
              <ion-label class="fs-3"> {{ dateTransactionFormatted || '12 Jun 2022' }}</ion-label>
            </ion-row>
          </ion-col>
          <ion-col size="auto">
            <ion-icon class="f-icon" :icon="chevronDownOutline"></ion-icon>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="box mx-3 mb-2" @click="openPicker(1)" v-if="isBankTransfer">
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col size="auto">
            <ion-icon class="f-icon" :icon="calendarOutline"></ion-icon>
          </ion-col>
          <ion-col>
            <ion-row>
              <ion-label class="fs-2 pb-2 text-gray-600"> {{ labelPayment }}</ion-label>
            </ion-row>
            <ion-row>
              <ion-label class="fs-3"> {{ datePaymentFormatted || '12 Jun 2022' }}</ion-label>
            </ion-row>
          </ion-col>
          <ion-col size="auto">
            <ion-icon class="f-icon" :icon="chevronDownOutline"></ion-icon>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="box mx-3 mb-2" @click="openPicker(3)" v-if="!isBankTransfer">
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col size="auto">
            <ion-icon class="f-icon" :icon="calendarOutline"></ion-icon>
          </ion-col>
          <ion-col>
            <ion-row>
              <ion-label class="fs-2 pb-2 text-gray-600">
                {{ paymentType === 3 ? labelCheque : paymentType === 8 ? labelDeposit : labelCollDate }}
              </ion-label>
            </ion-row>
            <ion-row>
              <ion-label class="fs-3"> {{ dateDepositFormatted }}</ion-label>
            </ion-row>
          </ion-col>
          <ion-col size="auto">
            <ion-icon class="f-icon" :icon="chevronDownOutline"></ion-icon>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <ion-label class="ml-5 mt-1 text-gray-400 fs-2"> {{ labelTheDate }} </ion-label>
  </div>
</template>
<script>
import { getDay, getMonth, getYear } from '@/utils/date-picker-invoice';
import { pickerController } from '@ionic/vue';
import { calendarOutline, chevronDownOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import moment from 'moment';
export default defineComponent({
  name: 'calender-picker',
  emits: ['dateTransaction', 'datePayment', 'dateDeposit'],
  props: {
    isBankTransfer: {
      type: Boolean,
      default: false
    },
    paymentType: {
      type: Number,
      default: 0
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const labelTransaction = t('transaction_date');
    const labelPayment = t('payment_value_date');
    const labelDeposit = t('deposit_date');
    const labelCheque = t('cheque_date');
    const labelCollDate = t('cash_collection_date');
    const labelTheDate = t('the_date_that_effective');
    const dateTransaction = ref(new Date());
    const isBankTransfer = ref(props.isBankTransfer);
    const dateTransactionFormatted = ref(moment(dateTransaction.value).format('DD MMM YYYY'));
    const datePayment = ref(new Date());
    const datePaymentFormatted = ref(moment(datePayment.value).format('DD MMM YYYY'));
    const dateDeposit = ref(new Date());
    const dateDepositFormatted = ref(moment(dateDeposit.value).format('DD MMM YYYY'));
    const openPicker = async (type) => {
      const picker = await pickerController.create({
        columns: [
          {
            name: 'month',
            options: getMonth,
            selectedIndex: dateTransaction.value.getMonth()
          },
          {
            name: 'day',
            options: getDay,
            selectedIndex: dateTransaction.value.getDate() - 1
          },
          {
            name: 'year',
            options: getYear,
            selectedIndex: getYear.map((item) => item.value).indexOf(`${dateTransaction.value.getFullYear()}`)
          }
        ],
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel'
          },
          {
            text: t('apply'),
            handler: (value) => {
              const month = value.month.value;
              const day = value.day.value;
              const year = value.year.value;
              if (type === 0) {
                dateTransaction.value = new Date(year, month - 1, day);
                dateTransactionFormatted.value = moment(dateTransaction.value).format('DD MMM YYYY');
                emit('dateTransaction', moment(dateTransaction.value).toDate());
                const futureDate = moment().add(1, 'hours');
                const compareDate = moment(dateTransaction.value).diff(futureDate);
                if (compareDate > 0) {
                  dateTransaction.value = new Date();
                  dateTransactionFormatted.value = moment(dateTransaction.value).format('DD MMM YYYY');
                }
              } else if (type === 1) {
                datePayment.value = new Date(year, month - 1, day);
                datePaymentFormatted.value = moment(datePayment.value).format('DD MMM YYYY');
                emit('datePayment', moment(datePayment.value).toDate());
                if (!isBankTransfer.value) {
                  const futureDate = moment().add(1, 'hours');
                  const compareDate = moment(datePayment.value).diff(futureDate);
                  if (compareDate < 0) {
                    datePayment.value = new Date();
                    datePaymentFormatted.value = moment(datePayment.value).format('DD MMM YYYY');
                  }
                }
              } else {
                dateDeposit.value = new Date(year, month - 1, day);
                dateDepositFormatted.value = moment(dateDeposit.value).format('DD MMM YYYY');
                emit('dateDeposit', moment(dateDeposit.value).toDate());
                const futureDate = moment().add(1, 'hours');
                const compareDate = moment(dateDeposit.value).diff(futureDate);
                if (compareDate > 0) {
                  dateDeposit.value = new Date();
                  dateDepositFormatted.value = moment(dateDeposit.value).format('DD MMM YYYY');
                }
              }
            }
          }
        ],
        animated: true
      });
      picker.present();
    };
    return {
      openPicker,
      labelTransaction,
      labelTheDate,
      labelPayment,
      calendarOutline,
      chevronDownOutline,
      dateTransactionFormatted,
      datePaymentFormatted,
      dateDepositFormatted,
      labelDeposit,
      labelCheque,
      labelCollDate
    };
  }
});
</script>
<style lang="scss" scoped>
.box {
  border: 1px solid var(--ion-color-text-gray-300);
  background-color: var(--ion-color-text-gray-100);
  border-radius: 10px;
  min-height: 56px;
  padding: 5px 16px 5px 16px;
}
.f-icon {
  font-size: 27px;
  color: gray;
}
</style>
