<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <ion-icon
          @click="goBack"
          size="large"
          color="primary"
          slot="start"
          :icon="chevronBackOutline"
        ></ion-icon>
        <ion-label class="fw-600" @click="goBack">{{ labelNavbar }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <skeleton-payment-details v-if="loading"></skeleton-payment-details>
    <ion-content v-else>
      <div class="mx-3 mt-4 d-flex flex-column">
        <ion-label color="primary" class="fs-3 fw-500 pb-2">{{ labelHeader }}</ion-label>
      </div>
      <calender-picker
        :paymentType="paymentType"
        :isBankTransfer="paymentType === 4"
        @dateTransaction="updateDateTransaction"
        @datePayment="updateDatePayment"
        @dateDeposit="updateDateDeposit"
      />
      <drop-zone class="drop-area" @files-dropped="onImageChange">
        <ion-input
          type="file"
          accept="image/*;capture=camera"
          @ionInput="onImageChange"
          ref="imageUpload"
          class="d-none"
        >
        </ion-input>
        <payment-proof
          @triggerBottomSheet="setBottomSheetUpload(true)"
          :data="imageProof"
          @deleteImage="deleteImage"
          @onTapFullSizeImg="onTapFullSizeImg"
        />
      </drop-zone>
      <input-remarks
        @inputRemarks="onChangeInputRemarks"
        @clearInput="clearInput"
        :inputValue="isBankTransferOrCashDeposit ? note : referenceCode"
        :isInputNote="isBankTransferOrCashDeposit"
      />
      <summary-info
        v-if="isSalesUser"
        :selectedInvoices="invoices"
        :currencySymbol="currencySymbol"
        :customerCreditAllocation="customerCreditAmount"
        :totalInvoice="totalAmount"
        :grandTotal="grandTotal"
        :totalPaidAmount="totalPaidAmount"
      />
    </ion-content>
    <skeleton-bottom v-if="loading"></skeleton-bottom>
    <bottom-content
      v-else
      @handlePayInvoices="createPayment"
      :totalPaidAmount="totalPaidAmount"
      :currencySymbol="currencySymbol"
      :disabledPay="disabledPay"
    />
    <!-- //modals -->
    <ion-modal :is-open="isSummary" :backdropDismiss="true">
      <payment-summary
        @backToInvoice="backToInvoice"
        @backToHome="backToHome"
        :currencySymbol="currencySymbol"
        :paymentSummary="paymentSummary"
        :invoice="invoices"
        :isBankTransfer="isBankTransferOrCashDeposit"
        :paymentType="paymentType"
      />
    </ion-modal>
    <ion-modal
      :is-open="isBottomSheetUpload"
      :backdropDismiss="true"
      :initial-breakpoint="0.2"
      @didDismiss="setBottomSheetUpload(false)"
      class="bor-15"
    >
      <ion-content>
        <ion-input
          placeholder=""
          type="file"
          accept="image/*;capture=camera"
          @ionInput="onImageChange"
          ref="imageUpload"
          class="d-none"
        >
        </ion-input>
        <ion-list>
          <ion-item>
            <ion-label @click="triggerUpload" class="text-center" color="primary">{{
              $t('open_document')
            }}</ion-label>
          </ion-item>
          <ion-item v-if="isNative">
            <ion-label @click="triggerOpenCam" class="text-center" color="primary">{{
              $t('camera')
            }}</ion-label>
          </ion-item>
          <ion-button @click="setBottomSheetUpload(false)" fill="outline" expand="block" class="no-border">
            <ion-label class="text-capitalize fw-600" color="primary">{{ $t('cancel') }}</ion-label>
          </ion-button>
        </ion-list>
      </ion-content>
    </ion-modal>
    <!-- show full size image -->
    <ion-modal :is-open="isOpenFullImage" :backdropDismiss="false" @didDismiss="setOpenFullImage(false)">
      <ion-header>
        <ion-toolbar>
          <ion-icon
            @click="setOpenFullImage(false)"
            size="large"
            color="primary"
            slot="end"
            :icon="closeCircleOutline"
          ></ion-icon>
        </ion-toolbar>
      </ion-header>
      <div class="image-container">
        <img :src="imagePreview" alt="img-preview" />
      </div>
    </ion-modal>

    <ion-toast
      :is-open="imgError"
      mode="ios"
      color="danger"
      :message="imgErrorMessage"
      :duration="2000"
      position="top"
      @didDismiss="imgError = false"
    >
    </ion-toast>
    <ion-toast
      :is-open="isInvalidDateFuture || isInvalidDatePast"
      mode="ios"
      color="danger"
      :message="
        isInvalidDateFuture
          ? `${$t('future_date_error')} ${todayDate}!`
          : `${$t('past_date_error')} ${todayDate}!`
      "
      :duration="2000"
      position="top"
      @didDismiss="(isInvalidDateFuture = false), (isInvalidDatePast = false)"
    >
    </ion-toast>
    <ion-loading :is-open="isLoading" :message="$t('please_wait')" cssClass="my-custom-class" />
  </ion-page>
</template>
<script>
// packages
import { fileToBase64 } from '@/modules/sale/services/libs/image';
import { ACTIONS } from '@/modules/sale/store/payment/actions';
import { INVOICE_PAYMENT_METHOD, INVOICE_PAYMENT_TYPE } from '@/modules/shared/constants/';
import Clevertap from '@/services/shared/helper/clevertap';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { alertController, useBackButton } from '@ionic/vue';
import { chevronBackOutline, closeCircleOutline, helpCircleOutline } from 'ionicons/icons';
import moment from 'moment';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { createNamespacedHelpers } from 'vuex';

// components
import DropZone from '@/components/DropZone';
import {
  PaymentProof,
  SkeletonBottom,
  SkeletonPaymentDetails
} from '@/modules/shared/components/invoices-payment';
import SummaryInfo from '../select-payment/components/SummaryInfo.vue';
import { BottomContent, CalenderPicker, InputRemarks, PaymentSummary } from './components';

const { mapActions, mapGetters } = createNamespacedHelpers('sale/payment');

export default defineComponent({
  name: 'payment-details-sale',
  inject: ['$storage'],
  components: {
    CalenderPicker,
    InputRemarks,
    PaymentProof,
    BottomContent,
    PaymentSummary,
    SummaryInfo,
    SkeletonPaymentDetails,
    SkeletonBottom,
    DropZone
  },
  setup() {
    const isNative = Capacitor.isNativePlatform();
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(false);
    const paymentType = +route.query['payment-type'];
    const isSummary = ref(false);
    const isBottomSheetUpload = ref(false);
    const setBottomSheetUpload = (state) => (isBottomSheetUpload.value = state);
    const isSalesUser = ref(false);
    const { BANK_TRANSFER, CHEQUE, CASH_DEPOSIT, CASH_PAYMENT_COLLECTION } = INVOICE_PAYMENT_TYPE;
    const isBankTransferOrCashDeposit = [BANK_TRANSFER, CASH_DEPOSIT].includes(paymentType)
      ? ref(true)
      : ref(false);
    const toggleInputCredit = ref(false);
    const imageUpload = ref(null);
    const imageProof = ref([]);
    const note = ref('');
    const referenceCode = ref('');
    const todayDate = moment().format('YYYY-MM-DD');
    const isInvalidDateFuture = ref(false);
    const isInvalidDatePast = ref(false);
    const imgError = ref(false);
    const imgErrorMessage = ref('');
    const labelOptions = {
      [BANK_TRANSFER]: t('bank_transfer_detail'),
      [CHEQUE]: t('cheque_detail'),
      [CASH_PAYMENT_COLLECTION]: t('cash_payment_collection_detail'),
      [CASH_DEPOSIT]: t('cash_deposit_detail')
    };
    const labelHeader = labelOptions[paymentType];
    const labelNavbar = t('payment_details');
    const labelAmount = t('total_amount');
    const labelAvailCredit = t('use_available_credits');
    const labelRemainCredit = t('remaining_credits');
    const goBack = () => {
      router.back();
    };
    const backToInvoice = () => {
      isSummary.value = false;
      router.replace('/sale/invoices/select-invoices');
    };
    const backToHome = () => {
      isSummary.value = false;
      router.replace('/sale/main/home');
    };
    const triggerUpload = async () => {
      const ionInputEl = imageUpload.value.$el;
      const inputEl = await ionInputEl.getInputElement();
      inputEl.click();
    };
    const onImageChange = async (e) => {
      const files = e.target ? e.target.querySelector('input').files : e;
      if (!files.length) return;
      if (!files[0].type.includes('image')) {
        imgError.value = true;
        imgErrorMessage.value = t('image_error_type');
        return;
      }
      if (files[0].size / 1024 > 5000) {
        imgError.value = true;
        imgErrorMessage.value = t('image_error_size');
        return;
      }
      const file = {
        imgName: files[0].name,
        imgSize: (files[0].size / 1024).toFixed(2) + ' KB',
        imgUrl: await fileToBase64(files[0]),
        imgBase64: await fileToBase64(files[0])
      };
      imageProof.value.push(file);
      setBottomSheetUpload(false);
    };
    const triggerOpenCam = async () => {
      await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Uri
      }).then(async (image) => {
        const fetchImgPath = await fetch(String(image.webPath));
        const blob = await fetchImgPath.blob();
        const imgBase64 = await fileToBase64(blob);
        const padding = imgBase64.endsWith('==') ? 2 : imgBase64.endsWith('=') ? 1 : 0;
        const base64Length = imgBase64.length;
        const sizeInBytes = (base64Length / 4) * 3 - padding;
        const sizeInKb = (sizeInBytes / 1024).toFixed(2);
        if (sizeInKb > 5000) {
          imgError.value = true;
          imgErrorMessage.value = t('image_error_size');
          return;
        }
        const file = {
          imgName: image.path.split('/').pop(),
          imgSize: sizeInKb + ' KB',
          imgUrl: await String(image.webPath),
          imgBase64
        };
        imageProof.value.push(file);
        setBottomSheetUpload(false);
      });
    };
    const deleteImage = (idx) => {
      imageProof.value.splice(idx, 1);
    };

    const onChangeInputRemarks = (value) => {
      note.value = value;
      referenceCode.value = value;
    };
    const clearInput = () => {
      if (isBankTransferOrCashDeposit.value) note.value = '';
      else referenceCode.value = '';
    };
    const presentAlertPayment = async () => {
      const alert = await alertController.create({
        backdropDismiss: false,
        cssClass: 'my-custom-class',
        mode: 'ios',
        header: t('payment_pending'),
        message: t('payment_pending_info'),
        buttons: [
          {
            text: t('OK'),
            handler: () => {
              isSummary.value = true;
              deleteImage();
              note.value = '';
              referenceCode.value = '';
            }
          }
        ]
      });
      return alert.present();
    };

    const presentAlertError = async (message) => {
      const alert = await alertController.create({
        backdropDismiss: false,
        cssClass: 'my-custom-class',
        mode: 'ios',
        header: t('payment_creation_failed'),
        message: t(message),
        buttons: [
          {
            text: t('OK'),
            handler: () => {
              backToInvoice();
            }
          }
        ]
      });
      return alert.present();
    };
    const imagePreview = ref(null);
    const isOpenFullImage = ref(false);
    const setOpenFullImage = (state) => (isOpenFullImage.value = state);
    const onTapFullSizeImg = (imgUrl) => {
      imagePreview.value = imgUrl;
      setOpenFullImage(true);
    };
    useBackButton(10, () => {
      setOpenFullImage(false);
    });
    return {
      labelHeader,
      labelNavbar,
      labelAvailCredit,
      labelRemainCredit,
      isSummary,
      labelAmount,
      goBack,
      clearInput,
      presentAlertPayment,
      helpCircleOutline,
      chevronBackOutline,
      closeCircleOutline,
      toggleInputCredit,
      triggerUpload,
      triggerOpenCam,
      onImageChange,
      imageUpload,
      imageProof,
      deleteImage,
      note,
      referenceCode,
      presentAlertError,
      paymentType,
      isBankTransferOrCashDeposit,
      isLoading,
      todayDate,
      isInvalidDateFuture,
      isInvalidDatePast,
      onChangeInputRemarks,
      backToInvoice,
      backToHome,
      isSalesUser,
      router,
      route,
      imgError,
      imgErrorMessage,
      INVOICE_PAYMENT_TYPE,
      INVOICE_PAYMENT_METHOD,
      transactionDate: ref(''),
      paymentValueDate: ref(''),
      depositDate: ref(''),
      paymentProof: ref([]),
      creditAllocationNotes: ref(null),
      user: ref(null),
      userTypeID: ref(null),
      isAdmin: ref(false),
      totalPaidAmount: ref(0),
      isError: ref(false),
      isNative,
      isBottomSheetUpload,
      setBottomSheetUpload,
      onTapFullSizeImg,
      isOpenFullImage,
      imagePreview,
      setOpenFullImage
    };
  },
  async ionViewDidEnter() {
    this.user = await this.$storage.getUser();
    this.userTypeID = this.user.user_type_id;
    if (this.userTypeID === 2) this.isAdmin = true;
    this.totalPaidAmount = this.selectedPaymentInvoices?.totalPaidAmount;
    if (this.route.query?.isSalesUser) {
      this.isSalesUser = true;
    } else {
      this.isSalesUser = false;
    }
    if (INVOICE_PAYMENT_METHOD.DIGITAL.includes(this.paymentType)) {
      this.isSummary = true;
    }
  },
  watch: {
    totalPaidAmount(value) {
      this.totalPaidAmount = value;
    }
  },
  computed: {
    ...mapGetters(['paymentSummary', 'status', 'error', 'loading', 'selectedPaymentInvoices']),
    invoices() {
      return this.selectedPaymentInvoices?.selectedInvoices;
    },
    disabledPay() {
      if (this.isAdmin && !this.isBankTransferOrCashDeposit && this.referenceCode.length === 0) {
        return true;
      } else if (!this.isAdmin && this.imageProof.length === 0) {
        return true;
      } else if (!this.isAdmin && !this.isBankTransferOrCashDeposit && this.referenceCode.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    grandTotal() {
      return this.totalAmount - this.customerCreditAmount;
    },
    totalAmount() {
      return this.selectedPaymentInvoices?.totalInvoiceAmount;
    },
    currencySymbol() {
      return this.selectedPaymentInvoices?.selectedInvoices[0]?.currencySymbol;
    },
    customerCreditAmount() {
      return this.selectedPaymentInvoices?.customerCreditAllocation || 0;
    }
  },
  methods: {
    ...mapActions([ACTIONS.CREATE_BANK_PAYMENT, ACTIONS.CREATE_CASH_CHEQUE_PAYMENT]),
    updateDateTransaction(date) {
      const futureDate = moment().add(1, 'hours');
      const compareDate = moment(date).diff(futureDate);
      if (compareDate > 0) {
        this.isInvalidDateFuture = true;
        return;
      }
      this.transactionDate = moment(date).format('YYYY-MM-DD');
    },
    updateDatePayment(date) {
      const today = moment().date();
      const isSameDate = moment(date).date() === today;
      const futureDate = moment().add(1, 'hours');
      const compareDate = moment(date).diff(futureDate);
      if (isSameDate) {
        this.isInvalidDatePast = false;
        this.paymentValueDate = moment(date).format('YYYY-MM-DD');
      } else {
        if (compareDate < 0 && !this.paymentType === 4) {
          this.isInvalidDatePast = true;
        } else {
          this.isInvalidDatePast = false;
          this.paymentValueDate = moment(date).format('YYYY-MM-DD');
        }
      }
    },
    updateDateDeposit(date) {
      const futureDate = moment().add(1, 'hours');
      const compareDate = moment(date).diff(futureDate);
      if (compareDate > 0) {
        this.isInvalidDateFuture = true;
        return;
      }
      this.depositDate = moment(date).format('YYYY-MM-DD');
    },
    async createPayment() {
      Clevertap.onUserPayInvoice({
        paymentType: this.paymentType,
        amountSubmitted: this.totalPaidAmount
      });
      if ([INVOICE_PAYMENT_TYPE.BANK_TRANSFER].includes(this.paymentType))
        await this.createPaymentBankTransfer();
      else await this.createPaymentChequeCash();
      if (this.isError) return;
      if (this.error) this.presentAlertError(this.error.message);
      else this.presentAlertPayment();
    },

    async createPaymentBankTransfer() {
      this.isLoading = true;
      const imageProofData = this.imageProof.map((proof) => proof.imgBase64);
      if (!imageProofData.length && !this.isAdmin) {
        this.presentAlertError('missing_payment_proof');
        this.isError = true;
        return;
      }
      const paramsCreateBankPayment = {
        invoiceIDs: this.invoices.map((inv) => inv.id),
        transactionDate: this.transactionDate || this.todayDate,
        valueDate: this.paymentValueDate || this.todayDate,
        paymentTypeId: INVOICE_PAYMENT_TYPE.BANK_TRANSFER,
        paymentProof: imageProofData,
        referenceCode: ``,
        notes: this.note,
        totalPaidAmount: Number(this.totalPaidAmount.toFixed(2)),
        balanceAllocationAmount: this.customerCreditAmount
      };
      await this[ACTIONS.CREATE_BANK_PAYMENT]({ paramsCreateBankPayment });
      this.isLoading = false;
    },

    async createPaymentChequeCash() {
      this.isLoading = true;
      const imageProofData = this.imageProof.map((proof) => proof.imgBase64);
      if (!imageProofData.length && !this.isAdmin) {
        this.presentAlertError('missing_payment_proof');
        this.isError = true;
        return;
      }
      if (!this.referenceCode.length && ![INVOICE_PAYMENT_TYPE.CASH_DEPOSIT].includes(this.paymentType)) {
        this.presentAlertError('missing_transaction_reference_number');
        this.isError = true;
        return;
      }
      const paramsCreateCashChequePayment = {
        invoiceIDs: this.invoices.map((inv) => inv.id),
        depositDate: this.depositDate || this.todayDate,
        paymentTypeId: this.paymentType,
        paymentProof: imageProofData,
        referenceCode: [INVOICE_PAYMENT_TYPE.CASH_DEPOSIT].includes(this.paymentType)
          ? ``
          : this.referenceCode,
        notes: [INVOICE_PAYMENT_TYPE.CASH_DEPOSIT].includes(this.paymentType) ? this.note : '',
        totalPaidAmount: Number(this.totalPaidAmount.toFixed(2)),
        balanceAllocationAmount: this.customerCreditAmount
      };
      await this[ACTIONS.CREATE_CASH_CHEQUE_PAYMENT]({ paramsCreateCashChequePayment });
      this.isLoading = false;
    }
  }
});
</script>
<style lang="scss" scoped>
.f-price {
  font-weight: 700;
  font-size: 22px;
}
.bor-15 {
  --border-radius: 15px;
}
.no-border {
  --border-color: none;
  --border-width: 0px !important;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
